/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const VisuallyHidden = React.forwardRef((props, ref) => {
  const { as: TagName = "span", children, ...rest } = props
  return (
    <TagName
      ref={ref}
      {...rest}
      sx={{
        border: 0,
        clip: "rect(0 0 0 0)",
        height: "1px",
        margin: "-1px",
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: "1px",

        // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
        whiteSpace: "nowrap",
        wordWrap: "normal",
      }}
    >
      {children}
    </TagName>
  )
})

export default VisuallyHidden
