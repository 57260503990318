/** @jsx jsx */
import React, {useCallback} from 'react';
import { jsx, Alert, Flex, Close } from "theme-ui"
import cookie from 'js-cookie'
import ContainerWide from "src/components/ContainerWide"

const AlertBanner = ({ id, body, path, restrictToPaths = [], variant = "blue" }) => {
  const cookieName = `veohrc_banner_${id}`
  const hasDismissedBannner = useCallback(() => cookie.get(cookieName), [cookieName]);

  const [show, setShow] = React.useState(!hasDismissedBannner())
  const dismiss = () => {
    setShow(false);
    cookie.set(cookieName, true, { expires: 30 });
  }


  React.useEffect(() => {
    const initialState = () => {
      // Alert has already been dismissed by user
      if (hasDismissedBannner()) return false;
      // Alert is restricted to specific pages
      if (restrictToPaths.length) {
        return restrictToPaths.indexOf(path) !== -1;
      }
      return true;
    }
    setShow(initialState());
  }, [cookieName, hasDismissedBannner, path, restrictToPaths])

  if (!show) return null;

  return (
    <Alert
      variant={variant}
    >
      <ContainerWide sx={{width: '100%'}}>
        <Flex sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <div
            sx={{
              "a:focus-visible": {
                outlineColor: variant === "yellow" ? "white" : "yellow",
              }
            }}
            dangerouslySetInnerHTML={ {__html: body} }
          />
          <Close
            onClick={dismiss}
            sx={{
              variant: "button.void",
              flexShrink: 0,
              ml: 'auto',
              mr: -2,

              "&:focus-visible": {
                outlineColor: variant === "yellow" ? "white" : "yellow",
              }
            }}
          />
        </Flex>
      </ContainerWide>
    </Alert>
  )
}

export default AlertBanner
