/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import VisuallyHidden from "./VisuallyHidden"
import useStore from "src/store"

const ButtonNavHeader = (props) => {
  const { menuItem, onClick, _sx } = props
  const activeNav = useStore((state) => state.nav)
  const isActive = activeNav === menuItem.id

  return (
    <button
      onClick={onClick}
      sx={{
        blockSize: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "2",
        variant: "button.navTop",
        letterSpacing: ".025em",
        lineHeight: 1.1,
        position: "relative",
        "&:after": {
          variant: "button.underline",
          position: "revert",
          inlineSize: "100%",
          backgroundColor: isActive ? "yellow" : "grey",
          "&": activeNav && {
            backgroundColor: isActive ? "yellow" : "hsl(0 0% 92% / 1)",
          },
        },
        "&:hover": !isActive && {
          opacity: 0.8,
        },
        "&:focus-visible": {
          outlineOffset: "4px",
          outlineColor: isActive
            ? "yellow"
            : props.activeTheme === "dark"
            ? "white"
            : "yellow",
        },
        ..._sx,
      }}
    >
      <VisuallyHidden>{isActive ? "Close" : "Open"}</VisuallyHidden>
      {menuItem.label}
      <VisuallyHidden>dialog window</VisuallyHidden>
    </button>
  )
}

ButtonNavHeader.propTypes = {
  isActive: PropTypes.bool,
}

export default ButtonNavHeader
