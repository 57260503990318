/** @jsx jsx */
import { jsx } from "theme-ui"

export default props => {
  return (
    <div
      {...props}
      sx={{
        variant: "layout.containerWide",
      }}
    />
  )
}
