/** @jsx jsx */
import { jsx } from "theme-ui"

const Columns = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        m: -2,
      }}
    />
  )
}

export default Columns
