/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { bgThemes } from "src/theme"

import SectionHero from "src/components/SectionHero"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import Breadcrumb from "src/components/Breadcrumb"
import {
  HeroWrap,
  HeroTitle,
  HeroIntroduction,
} from "src/components/HeroComponents"

const HeroStandard = (props) => {
  const {
    title,
    introduction,
    breadcrumbs,
    bgColor,
    children,
    isRTL,
    buttonLink,
    buttonColour,
  } = props

  return (
    <SectionHero
      className="hero-container"
      sx={{
        variant: "spacing.heroStandard",
        ...bgThemes[bgColor ? bgColor : "white"],
        "& *:focus-visible": bgColor === "yellow" && {
          outlineColor: "white !important",
        },
      }}
    >
      <Container>
        <Columns>
          <Column
            sx={{
              width: ["100%", null, null, "75%"],
            }}
          >
            <div
              sx={{
                py: [7],
              }}
            >
              <Breadcrumb isRightAligned={isRTL} breadcrumbs={breadcrumbs} />
              <HeroWrap
                isRTL={isRTL}
                buttonLink={buttonLink}
                buttonColour={buttonColour}
                bgColor={bgColor || "white"}
              >
                <HeroTitle>{title}</HeroTitle>
                <HeroIntroduction>{introduction}</HeroIntroduction>
              </HeroWrap>
              {children}
            </div>
          </Column>
        </Columns>
      </Container>
    </SectionHero>
  )
}

HeroStandard.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  isRTL: PropTypes.bool,
  children: PropTypes.node,
}

HeroStandard.defaultProps = {
  children: null,
  isRTL: false,
}

export default HeroStandard
