/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import get from "lodash.get"

import Column from "src/components/Column"
import Link from "src/components/Link"

const NavMainDropdownColumn = (props) => {
  const { menuItem, primary } = props
  const childItemNodes = get(menuItem, "childItems.nodes", [])

  return (
    <Column
      as="li"
      sx={{
        width: "25%",
        pr: [5],
      }}
    >
      <p
        sx={{
          fontSize: "1rem",
          color: "grey",
          minHeight: "3rem",
          mb: "1rem",
        }}
        className={menuItem.cssClasses.join(" ")}
      >
        {menuItem.label}
      </p>

      {childItemNodes && (
        <ul>
          {childItemNodes.map((childItem, index) => {
            return (
              <li
                key={childItem.id + index}
                sx={
                  primary
                    ? {
                        variant: "styles.h3",
                      }
                    : {
                        variant: "styles.p",
                        mb: 2,
                      }
                }
              >
                <Link
                  sx={{
                    variant: "link.text",
                  }}
                  to={childItem.url}
                >
                  {childItem.label}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </Column>
  )
}

export default NavMainDropdownColumn
