/** @jsx jsx */
import { jsx } from "theme-ui"

import PropTypes from "prop-types"
import { SocialIcon } from "react-social-icons"

const SocialLink = ({ link, label, color, network }) => {
  return (
    <SocialIcon
      url={link}
      network={network}
      fgColor={color}
      bgColor="transparent"
      label={label}
      style={{
        color,
        "&:hover": {
          opacity: 0.75,
        },
        marginBottom: "24px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        overflow: "visible",
        width: "fit-content",
        height: "1.125em",
      }}
      sx={{
        variant: "links.footerNav",
        "& > div": {
          position: "revert !important",
          width: "30px !important",
          height: "revert !important",
          "& > svg": {
            display: "block",
            position: "revert !important",
          },
        },
      }}
    >
      <span sx={{ lineHeight: "1.125" }}>{label}</span>
    </SocialIcon>
  )
}

const SocialLinks = ({ links }) => {
  return (
    <div>
      <div
        sx={{
          columnCount: [1, 2, 1],
          mb: [5, null, 0],
        }}
      >
        {links.socialLinkFacebook && (
          <SocialLink
            link={links.socialLinkFacebook}
            label="Facebook"
            network="facebook"
            color="#3B5998"
          />
        )}

        {links.socialLinkTwitter && (
          <SocialLink
            link={links.socialLinkTwitter}
            label="Twitter"
            network="twitter"
            color="#0B7BC1"
          />
        )}

        {links.socialLinkYoutube && (
          <SocialLink
            link={links.socialLinkYoutube}
            label="Youtube"
            network="youtube"
            color="#EB0000"
          />
        )}

        {links.socialLinkLinkedin && (
          <SocialLink
            link={links.socialLinkLinkedin}
            label="LinkedIn"
            network="linkedin"
            color="#0077B5"
          />
        )}

        {links.socialLinkInstagram && (
          <SocialLink
            link={links.socialLinkInstagram}
            label="Instagram"
            network="instagram"
            color="#000000"
          />
        )}
      </div>
      {links.socialLinkNewsletter && (
        <SocialLink
          link={links.socialLinkNewsletter}
          label="Sign up to our newsletter"
          network="email"
          color="#000000"
        />
      )}
    </div>
  )
}

SocialLinks.propTypes = {
  links: PropTypes.object.isRequired,
}

export default SocialLinks
