/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"

import Link from "src/components/Link"
import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

const GetHelpBlock = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query GetHelpBlock {
          wpgraphql {
            acfGlobalOptions {
              getHelpActive
              getHelpTitle
              getHelpIntroduction
              getHelpLinks {
                target
                title
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          getHelpActive,
          getHelpTitle,
          getHelpIntroduction,
          getHelpLinks,
        } = data.wpgraphql.acfGlobalOptions

        return !!getHelpActive ? (
          <Section
            as="aside"
            className="section-bg"
            sx={{ variant: "section.largeColored", bg: "blue", color: "white" }}
          >
            <Container
              sx={{
                bg: "blue",
                color: "white",
              }}
            >
              <Columns>
                <Column
                  sx={{
                    width: [null, null, "50%"],
                  }}
                >
                  <Themed.h2>{getHelpTitle}</Themed.h2>
                  <p
                    sx={{
                      variant: "text.intro",
                    }}
                  >
                    {getHelpIntroduction}
                  </p>
                </Column>
                <Column
                  sx={{
                    width: [null, null, "41.6666%"],
                    ml: [null, null, "8.3333%"],
                  }}
                >
                  {getHelpLinks.map((link, index) => {
                    if (link.url) {
                      return (
                        <Themed.h3 key={index} sx={{ mb: "1em" }}>
                          <Link
                            to={link.url}
                            sx={{
                              variant: "link.text",
                              color: "green",
                              py: 2,
                            }}
                          >
                            {link.title}
                          </Link>
                        </Themed.h3>
                      )
                    }
                  })}
                </Column>
              </Columns>
            </Container>
          </Section>
        ) : null
      }}
    />
  )
}

export default GetHelpBlock
