/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Link from "src/components/Link"
import { pxToRem } from "src/helpers"

const Breadcrumb = ({ isRightAligned, breadcrumbs }) => {
  return (
    <nav
      aria-label="Breadcrumb"
      sx={{
        mb: pxToRem([24, null, null, 40, 40]),
        display: "flex",
        flexDirection: "column",
        alignItems: isRightAligned ? "flex-end" : "flex-start",
      }}
    >
      <ol
        sx={{
          listStyle: "none",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {breadcrumbs.map((crumb, index) => {
          return (
            <li
              key={index}
              sx={{
                "&:after": {
                  content: "'/'",
                  px: 2,
                },
              }}
            >
              <Link to={crumb.link}>{crumb.title}</Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

Breadcrumb.propTypes = {
  isRightAligned: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

Breadcrumb.defaultProps = {
  isRightAligned: false,
}

export default Breadcrumb
