/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"
import { focusOutline } from "src/theme"
import useStore from "src/store"
import relativeUrl from "src/helpers/relativeUrl"

const LINK_TYPES = {
  relative: "relative",
  outbound: "outbound",
  mailOrTel: "mailOrTel",
  fragment: "fragment",
}

const getLinkType = (url) => {
  if (/^http:|^https:/.test(url)) return LINK_TYPES.outbound
  if (/^mailto:|^tel:/.test(url)) return LINK_TYPES.mailOrTel
  if (/^#/.test(url)) return LINK_TYPES.fragment
  return LINK_TYPES.relative
}

const Link = React.forwardRef((props, ref) => {
  const url = relativeUrl(props.to)
  const linkType = getLinkType(url)
  const setNav = useStore((state) => state.setNav)
  const closeMobileNav = useStore((state) => state.closeMobileNav)
  const sx = props.sx ? props.sx : {}

  const styles = {
    color: "inherit",
    ...focusOutline,
    "&:hover": {
      opacity: 0.8,
    },
    ...sx,
  }

  return (
    <>
      {linkType === LINK_TYPES.relative && (
        <GatsbyLink
          {...props}
          sx={styles}
          to={url}
          onClick={() => {
            if (window && url === window.location.pathname) {
              setNav(null)
              closeMobileNav()
            }
          }}
          ref={ref}
        />
      )}
      {linkType === LINK_TYPES.outbound && (
        <a {...props} href={url} sx={styles} ref={ref}></a>
      )}
      {linkType === LINK_TYPES.mailOrTel && (
        <a {...props} href={url} sx={styles} ref={ref}>
          {props.children}
        </a>
      )}
      {linkType === LINK_TYPES.fragment && (
        <a {...props} href={url} sx={styles} ref={ref}>
          {props.children}
        </a>
      )}
    </>
  )
})

export default Link
