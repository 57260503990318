/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { useState, useEffect } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { useTransition, animated } from "react-spring"

import Link from "src/components/Link"
import usePrefersReducedMotion from "src/hooks/usePrefersReducedMotion"
import ChevronDown from "src/assets/svg/chevron-down.inline.svg"
import ChevronUp from "src/assets/svg/chevron-up.inline.svg"
import IconClose from "src/assets/svg/close.inline.svg"
import useStore from "src/store"
import VisuallyHidden from "./VisuallyHidden"

const LevelThree = ({ menuItem }) => {
  return (
    <li>
      <Link
        to={menuItem.url}
        sx={{
          display: "block",
          p: "10px",
          variant: "text.mobNavLink3",
        }}
      >
        {menuItem.label}
      </Link>
    </li>
  )
}

const LevelTwo = ({ menuItem }) => {
  return (
    <li
      sx={{
        "&:first-of-type > div": {
          pt: "14px",
        },
      }}
    >
      <div
        sx={{
          p: "10px",
          pt: 5,
          variant: "text.mobNavLink2",
        }}
        className={menuItem.cssClasses.join(" ")}
      >
        {menuItem.label}
      </div>
      <ul>
        {menuItem.childItems.nodes.map((menuItemNode, index) => (
          <LevelThree menuItem={menuItemNode} key={index} />
        ))}
      </ul>
    </li>
  )
}

const LevelOne = ({ menuItem }) => {
  const [active, setActive] = useState(false)
  return (
    <li
      sx={{
        py: "10px",
        pl: 20,
        pr: "10px",
        bg: active && "rgba(255,255,255,0.2)",
      }}
    >
      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Link
          sx={{
            variant: "text.mobNavLink1",
          }}
          to={menuItem.url}
        >
          {menuItem.label}
        </Link>
        <button
          type="button"
          aria-expanded={active}
          aria-controls={menuItem.id}
          aria-label={`More ${menuItem.label} pages`}
          onClick={() => setActive(!active)}
          sx={{
            variant: "button.reset",
            "&::marker": { content: "''" },
          }}
        >
          <span
            aria-hidden="true"
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minInlineSize: "44px",
              minBlockSize: "44px",
            }}
          >
            {active ? <ChevronUp /> : <ChevronDown />}
          </span>
        </button>
      </Flex>
      <ul
        id={menuItem.id}
        sx={{
          py: "10px",
          display: active ? "block" : "none",
        }}
      >
        {menuItem.childItems.nodes.map((menuItemNode, index) => (
          <LevelTwo menuItem={menuItemNode} key={index} />
        ))}
      </ul>
    </li>
  )
}

const NavMain = ({ instance, attr, menuItems, ctas }) => {
  // `instance` is the `a11y-dialog` instance.
  // `attr` is an object with the following keys:
  // - `container`: the dialog container
  // - `overlay`: the dialog overlay (sometimes called backdrop)
  // - `dialog`: the actual dialog box
  // - `title`: the dialog mandatory title
  // - `closeButton`:  the dialog close button

  const mobileNav = useStore((state) => state.mobileNav)
  const closeMobileNav = useStore((state) => state.closeMobileNav)
  const setMobileNav = useStore((state) => state.setMobileNav)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const showHandler = () => {
      setMobileNav(instance.shown)
      disableBodyScroll(instance.$el, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => el.closest(`nav[aria-label="main"]`),
      })
      setIsOpen(true)
      setTimeout(() => {
        instance.$el.focus()
      }, 1) // Focus was staying on the menu button and instance.$el.focus() without the setTimeout doesn't work
    }

    const hideHandler = () => {
      enableBodyScroll(instance.$el)
      closeMobileNav()
      setTimeout(() => {
        setIsOpen(false)
      }, 1000)
    }

    if (instance) {
      instance.on("show", showHandler).on("hide", hideHandler)
    }

    return () => {
      clearAllBodyScrollLocks()
      if (instance) {
        instance.off("show", showHandler).off("hide", hideHandler)
        instance.destroy()
      }
    }
  }, [instance])

  const prefersReducedMotion = usePrefersReducedMotion()
  const transitionsInOut = useTransition(instance?.shown, {
    from: { transform: "translateX(100%)" },
    enter: { transform: "translateX(0%)" },
    leave: { transform: "translateX(100%)" },
    immediate: prefersReducedMotion,
  })

  let navDialog = null

  if (typeof window !== "undefined") {
    navDialog = createPortal(
      <div
        {...attr.container}
        aria-label="main navigation"
        sx={{
          position: "fixed",
          inset: 0,
          zIndex: 2,
          display: isOpen ? "flex" : "none",
        }}
      >
        {mobileNav && (
          <div
            {...attr.overlay}
            sx={{
              position: "fixed",
              inset: 0,
              background: "rgba(0,0,0,0.5)",
              cursor: "pointer",
            }}
          />
        )}
        {transitionsInOut(
          (styles, item, _transitionObject, _siblingPosition) =>
            item && (
              <animated.div
                {...attr.dialog}
                style={styles}
                sx={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                  maxWidth: "375px",
                  zIndex: 2,
                  bg: "blue",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  {...attr.closeButton}
                  sx={{
                    marginBlockStart: 20,
                    marginBlockEnd: 10,
                    mx: 10,
                    variant: "button.void",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "44px",
                    minHeight: "44px",
                    ml: "auto",
                    color: "white",
                  }}
                >
                  <IconClose />
                  <VisuallyHidden>
                    Close main navigation dialog window
                  </VisuallyHidden>
                </button>

                <VisuallyHidden as="p" {...attr.title}>
                  navigation menu
                </VisuallyHidden>

                <nav
                  aria-label="main"
                  sx={{
                    overflow: "auto",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ul
                    sx={{
                      flex: "auto",
                    }}
                  >
                    {menuItems.map((menuItem, index) => {
                      return <LevelOne menuItem={menuItem} key={index} />
                    })}
                  </ul>
                </nav>
                {ctas.map((Cta, key) => (
                  <Cta
                    key={key}
                    _sx={{
                      py: 20,
                      borderRadius: 0,
                    }}
                    isMobile={true}
                  />
                ))}
              </animated.div>
            )
        )}
      </div>,
      // Note: see gatsby-ssr.js for the dialog-portal render
      window.document.getElementById("dialog-portal")
    )
  }

  return navDialog
}

NavMain.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  cta: PropTypes.arrayOf(PropTypes.element),
}
export default NavMain
