/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import get from "lodash.get"

import Container from "src/components/Container"
import Section from "src/components/Section"
import Columns from "src/components/Columns"
import NavMainDropdownColumn from "src/components/NavMainDropdownColumn"
import Link from "src/components/Link"

const NavMainDropdown = (props) => {
  const { menuItem, menuItemIndex, titleAttrs } = props
  const childItemNodes = get(menuItem, "childItems.nodes", [])

  return (
    <Section
      id={menuItem.id}
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        background: "white",
        zIndex: 1,
        py: 7,
      }}
    >
      <Container>
        <p
          {...titleAttrs}
          sx={{
            variant: "styles.h2",
          }}
        >
          <Link
            to={menuItem.url}
            sx={{
              variant: "link.text",
            }}
          >
            {menuItem.label}
          </Link>
        </p>
        {childItemNodes && (
          <Columns as="ul">
            {childItemNodes.map((childItem, index) => {
              return (
                <NavMainDropdownColumn
                  key={index}
                  menuItem={childItem}
                  primary={index === 0 && menuItemIndex === 0}
                />
              )
            })}
          </Columns>
        )}
      </Container>
    </Section>
  )
}

NavMainDropdown.propTypes = {
  menuItemIndex: PropTypes.number.isRequired,
  menuItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    childItems: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
}

export default NavMainDropdown
