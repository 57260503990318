import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import useStore from "src/store"

function SEO({ description, lang, title, image }) {
  const mobileNav = useStore((state) => state.mobileNav)

  const {
    site: {
      siteMetadata: { title: siteTitle, description: siteDescription },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const metaDescription = description || siteDescription
  let cardImage = undefined
  if (image && image.sourceRemote) cardImage = image.sourceRemote
  else if (image && image.sourceLocal) cardImage = getSrc(image.sourceLocal)
  const cardType = cardImage ? "summary_large_image" : "summary"
  return (
    <Helmet
      htmlAttributes={{ lang }}
      bodyAttributes={{ class: mobileNav ? "nav-mobile" : "" }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
    >
      {/* General */}
      <meta name="description" content={metaDescription} />
      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:description" content={metaDescription} />
      {cardImage && <meta property="og:image" content={cardImage} />}
      {/* Twitter */}
      <meta name="twitter:card" content={cardType} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {cardImage && <meta name="twitter:image" content={cardImage} />}
      <meta
        name="facebook-domain-verification"
        content="iv1qaofcuajydz73ogh07lbuwr6qkb"
      />
      <meta
        name="facebook-domain-verification"
        content="kle4q79vfupcbdxumoaiup4rgo9wxy"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "en-au",
  title: "",
  description: "",
  image: null,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.shape({
    sourceLocal: PropTypes.object.isRequired,
  }),
}

export default SEO
