/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import get from "lodash.get"
import { pxToRem } from "src/helpers"
import Link from "src/components/Link"
import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import SocialLinks from "src/components/SocialLinks"
import GetHelpBlock from "src/components/GetHelpBlock"

import AustralianAboriginals from "src/assets/svg/australian-aboriginals.inline.svg"
import TorresStraitIslanders from "src/assets/svg/torres-strait-islanders.inline.svg"

function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query NavigationFooter {
          wpgraphql {
            acfGlobalOptions {
              contactColumnOne
              contactColumnTwo
              contactColumnThree
              footerStatement
            }
            acfSocialLinks {
              socialLinkFacebook
              socialLinkInstagram
              socialLinkLinkedin
              socialLinkNewsletter
              socialLinkTwitter
              socialLinkYoutube
            }
            menuItems(where: { location: NAVIGATION_FOOTER }, first: 100) {
              nodes {
                id
                label
                url
                childItems {
                  nodes {
                    id
                    label
                    url
                    childItems {
                      nodes {
                        id
                        label
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const menuItems = get(data, "wpgraphql.menuItems.nodes", [])
        const socialLinks = get(data, "wpgraphql.acfSocialLinks", {})
        const globalOptions = get(data, "wpgraphql.acfGlobalOptions", {})

        return (
          <Fragment>
            <GetHelpBlock />

            <footer
              sx={{
                position: "relative",
                "&:after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "4px",
                  variant: "gradient.default",
                },
              }}
            >
              <Section sx={{ variant: "section.large" }}>
                <Container>
                  <Columns>
                    <Column
                      sx={{
                        width: [null, null, "66.6666%", null, "75%"],
                      }}
                    >
                      <nav
                        sx={{
                          columnCount: [1, 2, null, null, 3],
                          columnGap: "16px",
                          mb: 5,
                        }}
                      >
                        {menuItems.map((menuItem, index) => {
                          return (
                            <Link
                              key={index}
                              to={menuItem.url}
                              sx={{
                                display: "block",
                                variant: "links.footerNav",
                                mb: 5,
                                pr: 3,
                              }}
                            >
                              {menuItem.label}
                            </Link>
                          )
                        })}
                      </nav>
                    </Column>
                    <Column
                      sx={{
                        width: [null, null, "33.3333%", null, "25%"],
                      }}
                    >
                      <SocialLinks links={socialLinks} />
                    </Column>
                  </Columns>
                </Container>
              </Section>
              <Section sx={{ variant: "section.large" }}>
                <Container>
                  <Columns>
                    <Column
                      sx={{
                        width: ["100%", null, null, "50%"],
                      }}
                    >
                      <div
                        sx={{
                          variant: "styles",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: globalOptions.contactColumnOne,
                        }}
                      />
                    </Column>
                    <Column
                      sx={{
                        width: ["100%", null, null, "25%"],
                      }}
                    >
                      <div
                        sx={{
                          variant: "styles",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: globalOptions.contactColumnTwo,
                        }}
                      />
                    </Column>
                    <Column
                      sx={{
                        width: ["100%", null, null, "25%"],
                      }}
                    >
                      <div
                        sx={{
                          variant: "styles",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: globalOptions.contactColumnThree,
                        }}
                      />
                    </Column>
                  </Columns>
                </Container>
              </Section>
              <Section sx={{ variant: "section.large" }}>
                <Container>
                  <div
                    sx={{
                      display: "flex",
                      mb: 20,
                    }}
                  >
                    <div
                      sx={{
                        width: [60, null, null, null, 80],
                        mr: 2,
                      }}
                    >
                      <AustralianAboriginals
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div
                      sx={{
                        width: [60, null, null, null, 80],
                      }}
                    >
                      <TorresStraitIslanders
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div
                    sx={{
                      variant: "styles",
                      maxWidth: pxToRem(900),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: globalOptions.footerStatement,
                    }}
                  />
                </Container>
              </Section>
            </footer>
          </Fragment>
        )
      }}
    />
  )
}

Footer.propTypes = {}

export default Footer
