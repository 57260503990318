/** @jsx jsx */
import { jsx } from "theme-ui"

export default (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        variant: "layout.container",
      }}
    />
  )
}
