/** @jsx jsx */
import { jsx, Flex, Grid } from "theme-ui"
import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import useStore from "src/store"
import get from "lodash.get"
import { useA11yDialog } from "react-a11y-dialog"

import { headerThemes, headerButtonThemes } from "src/theme"
import hasFeatureFlag from "src/helpers/hasFeatureFlag"
import Link from "src/components/Link"
import NavMain from "src/components/NavMain"
import NavMainMobile from "src/components/NavMainMobile"
import ContainerWide from "src/components/ContainerWide"
import Section from "src/components/Section"
import Hamburger from "src/components/Hamburger"
import AlertBanner from "src/components/AlertBanner"
import VisuallyHidden from "./VisuallyHidden"
import QuickExit from "./QuickExit"

import WorldIcon from "src/assets/svg/world.inline.svg"
import SearchIcon from "src/assets/svg/search.inline.svg"
import LogoDefault from "src/assets/svg/logo-default.inline.svg"
import LogoDark from "src/assets/svg/logo-dark.inline.svg"
import LogoLight from "src/assets/svg/logo-light.inline.svg"
import AustralianAboriginals from "src/assets/svg/australian-aboriginals.inline.svg"
import TorresStraitIslanders from "src/assets/svg/torres-strait-islanders.inline.svg"

const TranslationsLink = ({ _sx, isMobile, activeTheme }) => (
  <Link
    to={"/translations"}
    sx={{
      variant: "button.default",
      fontSize: 3,
      display: "flex",
      alignItems: "center",
      "> span": {
        marginLeft: "10px",
      },
      backgroundColor: isMobile ? "white" : "transparent",
      "&:focus-visible": {
        outlineColor: activeTheme === "dark" ? "white" : "yellow",
      },
      ..._sx,
    }}
  >
    <WorldIcon aria-hidden="true" />
    <span>Translations</span>
  </Link>
)

PropTypes.TranslationsLink = {
  activeTheme: PropTypes.string,
}

const SearchLink = ({ _sx, activeTheme }) => (
  <Link
    to={"/search"}
    sx={{
      variant: "button.default",
      fontSize: 3,
      display: "flex",
      alignItems: "center",
      backgroundColor: activeTheme === "dark" ? "white" : "yellow",
      color: "black",
      "> span": {
        marginLeft: "10px",
      },
      "&:focus-visible": {
        outlineColor: activeTheme === "dark" ? "white" : "yellow",
      },
      ..._sx,
    }}
  >
    <SearchIcon aria-hidden="true" />
    <span>Search</span>
  </Link>
)

PropTypes.SearchLink = {
  activeTheme: PropTypes.string,
}

// TODO: combine all these ⬆️ link components into one more abstracted one they can use/update in the CMS
const FirstNationsLink = ({ _sx, activeTheme }) => (
  <Link
    to={"/for-individuals/tailored-services"}
    sx={{
      variant: "button.default",
      fontSize: 3,
      display: "flex",
      alignItems: "center",
      backgroundColor: activeTheme === "dark" ? "white" : "yellow",
      color: "black",
      "> span": {
        marginLeft: "10px",
      },
      "> svg": {
        maxWidth: 24,
        height: 30,
      },
      "&:focus-visible": {
        outlineColor: activeTheme === "dark" ? "white" : "yellow",
      },
      ..._sx,
    }}
  >
    <AustralianAboriginals 
      aria-hidden="true"
      style={{
        marginRight: "8px",
      }}
    />
    <TorresStraitIslanders
      aria-hidden="true"
    />
    <span>First Nations</span>
  </Link>
)

PropTypes.FirstNationsLink = {
  activeTheme: PropTypes.string,
}

const MobileHeader = ({ props, data }) => {
  const headerRef = useRef(null)
  const navActive = useStore((state) => state.nav)
  const mobileNav = useStore((state) => state.mobileNav)
  const closeNav = useStore((state) => state.closeNav)

  const activeTheme = navActive
    ? "active"
    : props.defaultTheme
    ? props.defaultTheme
    : "default"

  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        closeNav()
      }
    }

    if (navActive) {
      headerRef.current?.addEventListener("keydown", keydownHandler)
    }

    return () => {
      headerRef.current?.removeEventListener("keydown", keydownHandler)
    }
  }, [navActive])

  const [instance, attr] = useA11yDialog({
    // The required HTML `id` attribute of the dialog element, internally used
    // a11y-dialog to manipulate the dialog.
    id: "mobile-nav",
    // The optional `role` attribute of the dialog element, either `dialog`
    // (default) or `alertdialog` to make it a modal (preventing closing on
    // click outside of ESC key).
    role: "dialog",
    // The required dialog title, mandatory in the document
    // to provide context to assistive technology.
    title: "main navigation",
  })

  const globalOptions = get(data.wpgraphql, "acfGlobalOptions", {})
  const translationFeatureFlag = hasFeatureFlag(
    globalOptions.featureFlags,
    "translations"
  )

  const firstNationsLinkFeatureFlag = hasFeatureFlag(
    globalOptions.featureFlags,
    "firstNationsLink"
  )

  const HeaderCta =
    globalOptions.headerCtaLink && globalOptions.headerCtaTitle
      ? ({ _sx }) => (
          <Link
            to={globalOptions.headerCtaLink}
            sx={{
              variant: "button.default",
              ...headerButtonThemes[activeTheme],
              fontSize: 3,
              alignItems: 'center',
              "&:focus-visible": {
                outlineColor: activeTheme === "dark" ? "white" : "yellow",
              },
              ..._sx,
            }}
          >
            {globalOptions.headerCtaTitle}
          </Link>
        )
      : () => null

  return (
    <div
      ref={headerRef}
      sx={{
        isolation: "isolate",
        position: "relative",
        zIndex: "1",
        marginBlockEnd: props.layout === "standard" ? "8px" : 0,
        backgroundColor:
          props.layout !== "image_background"
            ? props.themeColor
            : "transparent",

        "@media (min-width:1100px)": {
          display: "none",
        },
      }}
    >
      <header>
        {globalOptions.alertBannerEnabled && props.path && (
          <AlertBanner
            body={globalOptions.alertBannerBody}
            path={props.path}
            restrictToPaths={globalOptions.alertBannerHomepageOnly ? ["/"] : []}
            id={globalOptions.alertBannerId}
            variant={globalOptions.alertBannerTheme}
          />
        )}
        <Section
          sx={{
            paddingInline: ["2", "4", null, null, null, null, null],
            paddingBlock: "4",
            ...headerThemes[activeTheme],
          }}
        >
          <ContainerWide>
            <Grid
              sx={{
                gridTemplateColumns: "repeat(2, auto)",
                gap: "0",
                inlineSize: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pt: "0",
                  width: "auto",
                }}
              >
                <Link
                  to="/"
                  title="Go to the homepage"
                  sx={{
                    display: "block",
                    width: [200, null, 300],
                    svg: {
                      display: "block",
                      width: "100%",
                      height: "auto",
                    },
                    "&:focus-visible": {
                      outlineColor: activeTheme === "dark" ? "white" : "yellow",
                    },
                  }}
                >
                  {activeTheme === "dark" ? (
                    <LogoDark />
                  ) : activeTheme === "light" ? (
                    <LogoLight />
                  ) : (
                    <LogoDefault />
                  )}
                </Link>
              </div>
              <NavMainMobile
                instance={instance}
                attr={attr}
                menuItems={get(data.wpgraphql, "menuItems.nodes", [])}
                ctas={[
                  QuickExit,
                  SearchLink,
                  ...(translationFeatureFlag
                    ? [(props) => <TranslationsLink {...props} />]
                    : []),
                  ...(firstNationsLinkFeatureFlag
                    ? [FirstNationsLink]
                    : []),
                  HeaderCta,
                ]}
              />

              <button
                type="button"
                onClick={() => instance.show()}
                sx={{
                  variant: "button.reset",
                  display: "flex",
                  alignItems: "center",

                  "&:focus-visible": {
                    outlineColor: activeTheme === "dark" ? "white" : "yellow",
                  },
                }}
              >
                <strong
                  aria-hidden="true"
                  sx={{
                    variant: "text.bold",
                    textDecoration: "underline",
                  }}
                >
                  Menu
                </strong>
                <VisuallyHidden>
                  Open main navigation dialog window
                </VisuallyHidden>
                <Hamburger
                  aria-hidden="true"
                  style={{
                    display: "flex",
                    marginRight: "-10px",
                  }}
                  active={mobileNav}
                  type="spin"
                />
              </button>
            </Grid>
          </ContainerWide>
        </Section>
      </header>
    </div>
  )
}

const DesktopHeader = ({ props, data }) => {
  const navActive = useStore((state) => state.nav)

  const activeTheme = navActive
    ? "active"
    : props.defaultTheme
    ? props.defaultTheme
    : "default"

  const globalOptions = get(data.wpgraphql, "acfGlobalOptions", {})

  const firstNationsLinkFeatureFlag = hasFeatureFlag(
    globalOptions.featureFlags,
    "firstNationsLink"
  )

  const translationFeatureFlag = hasFeatureFlag(
    globalOptions.featureFlags,
    "translations"
  )

  const HeaderCta =
    globalOptions.headerCtaLink && globalOptions.headerCtaTitle
      ? ({ _sx }) => (
          <Link
            to={globalOptions.headerCtaLink}
            sx={{
              variant: "button.default",
              ...headerButtonThemes[activeTheme],
              fontSize: 3,
              "&:focus-visible": {
                outlineColor: activeTheme === "dark" ? "white" : "yellow",
              },
              ..._sx,
            }}
          >
            {globalOptions.headerCtaTitle}
          </Link>
        )
      : () => null

  return (
    <div
      sx={{
        display: "none",
        isolation: "isolate",
        position: "relative",
        zIndex: "1",
        marginBlockEnd: props.layout === "standard" ? "8px" : 0,
        backgroundColor:
          props.layout !== "image_background"
            ? props.themeColor
            : "transparent",

        "@media (min-width: 1100px)": {
          display: "block",
        },
      }}
    >
      <header>
        {globalOptions.alertBannerEnabled && props.path && (
          <div>
            {/* Had to add this div because the alert was messing up the header otherwise 🤷🏼‍♀️ */}
            <AlertBanner
              body={globalOptions.alertBannerBody}
              path={props.path}
              restrictToPaths={
                globalOptions.alertBannerHomepageOnly ? ["/"] : []
              }
              id={globalOptions.alertBannerId}
              variant={globalOptions.alertBannerTheme}
            />
          </div>
        )}
        <Section
          sx={{
            paddingInline: ["2", "4", null, null, null, null, null],
            paddingBlock: "4",
            ...headerThemes[activeTheme],
          }}
        >
          <ContainerWide>
            <Grid
              sx={{
                inlineSize: "100%",
                justifyContent: "space-between",
                gap: "4",
                gridTemplateColumns: "1fr",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pt: "0",
                  width: "100%",
                }}
              >
                <Link
                  to="/"
                  title="Go to the homepage"
                  sx={{
                    display: "block",
                    width: [200, null, 300],
                    svg: {
                      display: "block",
                      width: "100%",
                      height: "auto",
                    },
                    "&:focus-visible": {
                      outlineColor: activeTheme === "dark" ? "white" : "yellow",
                    },
                  }}
                >
                  {activeTheme === "dark" ? (
                    <LogoDark />
                  ) : activeTheme === "light" ? (
                    <LogoLight />
                  ) : (
                    <LogoDefault />
                  )}
                </Link>
                <Flex>
                  <QuickExit
                    _sx={{
                      marginRight: "20px",
                      paddingLeft: "15px",
                      display: "flex",
                      "&:focus-visible": {
                        outlineColor:
                          activeTheme === "dark" ? "white" : "yellow",
                      },
                    }}
                  />
                  {firstNationsLinkFeatureFlag && (
                    <FirstNationsLink
                      _sx={{
                        marginRight: "20px",
                        paddingLeft: "15px",
                        display: "flex",
                      }}
                      activeTheme={activeTheme}
                    />
                  )}
                  {translationFeatureFlag && (
                    <TranslationsLink
                      _sx={{
                        marginRight: "20px",
                        paddingLeft: "15px",
                        display: "flex",
                      }}
                      activeTheme={activeTheme}
                    />
                  )}
                  <HeaderCta
                    _sx={{
                      marginRight: "20px",
                      display: "block",
                    }}
                  />
                  <SearchLink
                    activeTheme={activeTheme}
                    _sx={{
                      paddingLeft: "15px",
                      display: "flex",
                    }}
                  />
                </Flex>
              </div>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "0",
                }}
              >
                <NavMain
                  menuItems={get(data.wpgraphql, "menuItems.nodes", [])}
                  activeTheme={activeTheme}
                />
              </div>
            </Grid>
          </ContainerWide>
        </Section>
      </header>
    </div>
  )
}

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query NavigationMain {
      wpgraphql {
        acfGlobalOptions {
          featureFlags
          headerCtaTitle
          headerCtaLink
          alertBannerEnabled
          alertBannerId
          alertBannerBody
          alertBannerTheme
          alertBannerHomepageOnly
        }
        menuItems(where: { location: NAVIGATION_MAIN }) {
          nodes {
            id
            label
            url
            childItems(first: 50) {
              nodes {
                id
                label
                url
                cssClasses
                childItems(first: 50) {
                  nodes {
                    id
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <MobileHeader props={props} data={data} />
      <DesktopHeader props={props} data={data} />
    </>
  )
}

Header.propTypes = {
  defaultColor: PropTypes.string,
  path: PropTypes.string,
}

Header.defaultProps = {
  defaultColor: "blue",
}

export default Header
