/** @jsx jsx */
import { jsx } from "theme-ui"

const Column = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        width: ["100%"],
        p: 2,
      }}
    />
  )
}

export default Column
