/** @jsx jsx */
import { jsx } from "theme-ui"

import ExternalIcon from "src/assets/svg/external.inline.svg"

/**
 * Get Away
 * https://css-tricks.com/website-escape/
 **/

const QuickExit = ({ _sx, isMobile }) => {
  const getAway = () => {
    if (typeof window !== "undefined") {
      // Get away right now
      window.open("http://weather.com", "_newtab")
      // Replace current site with another benign site
      window.location.replace("http://google.com")
    }
  }

  return (
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        getAway()
      }}
      sx={{
        variant: "button.default",
        fontSize: 3,
        display: "flex",
        alignItems: "center",
        backgroundColor: "red",
        color: "white",
        textDecoration: "none",
        "> span": {
          textDecoration: "underline",
          marginLeft: "10px",
        },
        ..._sx,
      }}
    >
      <ExternalIcon />
      <span>Quick exit</span>
    </a>
  )
}

export default QuickExit
