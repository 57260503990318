/** @jsx jsx */
import { jsx } from "theme-ui"

const Section = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        px: 4,
      }}
    />
  )
}

export default Section
