/** @jsx jsx */
import { jsx } from "theme-ui"

const Section = (props) => {
  const { as: TagName = "div", ...rest } = props
  return (
    <TagName
      {...rest}
      sx={{
        px: 4,
        mb: [5, 5, 6, 7],
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          bottom: "-2px",
          left: 0,
          right: 0,
          height: "4px",
          variant: "gradient.default",
        },
      }}
    />
  )
}

export default Section
