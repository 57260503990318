/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Link from "src/components/Link"
import isHTML from "src/helpers/isHTML"
import { bgThemes } from "src/theme"
import ScrollDownArrow from "../assets/svg/scroll-down.svg"

export const HeroWrap = ({
  isRTL,
  sx,
  buttonLink,
  buttonColour,
  bgColor,
  children,
  as = "div",
}) => {
  const As = as
  return (
    <As {...(isRTL && { dir: "rtl" })} sx={sx}>
      {buttonLink && buttonLink.url ? (
        <>
          {children}
          <HeroLink
            buttonLink={buttonLink}
            buttonColour={buttonColour}
            bgColor={bgColor}
          />
        </>
      ) : (
        children
      )}
    </As>
  )
}

HeroWrap.propTypes = {
  isRTL: PropTypes.bool,
  sx: PropTypes.shape({}),
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  buttonTheme: PropTypes.string,
  bgColor: PropTypes.string,
}

HeroWrap.defaultProps = {
  isRTL: false,
  sx: { variant: "text.intro" },
  link: undefined,
  as: "div",
}

export const HeroTitle = ({ children, sx = { variant: "text.heroTitle" } }) =>
  children && (
    <h1
      sx={sx}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  )

HeroTitle.propTypes = {
  children: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
}

HeroTitle.defaultProps = {
  sx: { variant: "text.heroTitle" },
}

export const HeroIntroduction = ({ children, sx }) =>
  children ? (
    <div
      sx={{
        variant: "text.intro",
        mb: 20,
        ...sx,
      }}
      //This component has changed from Text Field to WYSIWYG so need to cover
      //both string and HTML string
      {...(isHTML(children)
        ? { dangerouslySetInnerHTML: { __html: children } }
        : { children })}
    />
  ) : null

HeroIntroduction.propTypes = {
  children: PropTypes.string,
  sx: PropTypes.shape({}),
}

HeroIntroduction.defaultProps = {
  sx: {},
}

const HeroLink = ({ buttonLink, buttonColour, bgColor }) => {
  const [isCommunityReportingTool, setIsCommunityReportingTool] =
    useState(false)
  useEffect(() => {
    if (window === null) return
    // Custom check for the community reporting page path to add icon to 'Make report' button in the header.
    if (window.location.pathname === "/get-help/community-reporting-tool/") {
      setIsCommunityReportingTool(true)
    }
  }, [])

  // Make default header button colour green.
  // If the header background colour is anything but white, or has an image, make the button white.
  let defaultButtonTheme = bgColor === "white" ? "green" : "whiteAlt"

  // Override button colour
  if (buttonColour !== "default") {
    defaultButtonTheme = buttonColour
  }

  if (buttonLink && buttonLink.title && buttonLink.url) {
    return (
      <Link
        to={buttonLink.url}
        sx={{
          variant: "button.heroButton",
          display: "inline-block",
          ...bgThemes[buttonLink.theme || defaultButtonTheme],
        }}
      >
        {isCommunityReportingTool && (
          <img
            src={ScrollDownArrow}
            sx={{
              paddingRight: "8px",
              position: "relative",
              top: "4px",
              marginLeft: "-5px",
              marginTop: "-4px",
            }}
          />
        )}
        {buttonLink.title}
      </Link>
    )
  }
  return null
}

HeroLink.propTypes = {
  buttonLink: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    theme: PropTypes.string,
  }),
  bgColor: PropTypes.string,
}

HeroLink.defaultProps = {
  buttonLink: undefined,
}
