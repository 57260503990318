/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import NavMainParentItem from "src/components/NavMainParentItem"

const NavMain = (props) => {
  return (
    <nav aria-label="main">
      <ul
        sx={{
          display: "flex",
          gap: "5",
        }}
      >
        {props.menuItems.map((menuItem, index) => (
          <NavMainParentItem
            menuItem={menuItem}
            key={menuItem.id}
            menuItemIndex={index}
            activeTheme={props.activeTheme}
          />
        ))}
      </ul>
    </nav>
  )
}

NavMain.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default NavMain
