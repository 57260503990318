/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"
import { useA11yDialog } from "react-a11y-dialog"

import useStore from "src/store"
import ButtonNavHeader from "src/components/ButtonNavHeader"
import NavMainDropdown from "src/components/NavMainDropdown"

const NavMainParentItem = ({ menuItem, menuItemIndex, activeTheme }) => {
  const listItemRef = useRef(null)
  const toggleNav = useStore((state) => state.toggleNav)
  const closeNav = useStore((state) => state.closeNav)

  // `instance` is the `a11y-dialog` instance.
  // `attr` is an object with the following keys:
  // - `container`: the dialog container
  // - `overlay`: the dialog overlay (sometimes called backdrop)
  // - `dialog`: the actual dialog box
  // - `title`: the dialog mandatory title
  // - `closeButton`:  the dialog close button
  const [instance, attr] = useA11yDialog({
    // The required HTML `id` attribute of the dialog element, internally used
    // a11y-dialog to manipulate the dialog.
    id: `desktop-nav-${menuItem.id}`,
    // The optional `role` attribute of the dialog element, either `dialog`
    // (default) or `alertdialog` to make it a modal (preventing closing on
    // click outside of ESC key).
    role: "dialog",
    // The required dialog title, mandatory in the document
    // to provide context to assistive technology.
    title: menuItem.label,
  })

  useEffect(() => {
    const showHandler = () => {
      toggleNav(menuItem.id)
    }

    const hideHandler = () => {
      closeNav()
      setTimeout(() => {
        instance._previouslyFocused.focus()
      }, 1) // The internal focus() call on the previously focused element fires too early, so we needed the timeout
    }

    if (instance) {
      instance.on("show", showHandler).on("hide", hideHandler)
    }

    return () => {
      if (instance) {
        instance.off("show", showHandler).off("hide", hideHandler)
        instance.destroy()
      }
    }
  }, [instance])

  let dialog = null

  if (typeof window !== "undefined") {
    dialog = createPortal(
      <div
        {...attr.container}
        aria-label={menuItem.label}
        sx={{
          position: "absolute",
          inset: 0,
          zIndex: 2,
          top: listItemRef.current
            ? listItemRef.current.offsetTop + listItemRef.current.offsetHeight
            : 0,
          "&[aria-hidden='true']": {
            display: "none",
          },
        }}
      >
        <div
          {...attr.overlay}
          sx={{
            position: "fixed",
            inset: 0,
            background: `linear-gradient(transparent ${
              listItemRef.current
                ? listItemRef.current.offsetTop +
                  listItemRef.current.offsetHeight
                : 0
            }px, rgba(0,0,0,0.5) ${
              listItemRef.current
                ? listItemRef.current.offsetTop +
                  listItemRef.current.offsetHeight
                : 0
            }px)`,
            cursor: "pointer",
            zIndex: -1,
          }}
        />
        <div {...attr.dialog}>
          <div
            sx={{
              position: "absolute",
              top: listItemRef.current
                ? listItemRef.current.offsetHeight * -1
                : 0,
              left: listItemRef.current ? listItemRef.current.offsetLeft : 0,
              zIndex: 2,
              maxWidth: listItemRef.current
                ? listItemRef.current.offsetWidth + 1 // Not 100% sure why but it needs an extra pixel to not break the text sometimes
                : 0,
              "& > button": {
                minHeight: listItemRef.current
                  ? listItemRef.current.offsetHeight
                  : 0,
              },
            }}
          >
            <ButtonNavHeader
              menuItem={menuItem}
              activeTheme={activeTheme}
              onClick={() => instance.hide()}
            />
          </div>
          <NavMainDropdown
            menuItem={menuItem}
            menuItemIndex={menuItemIndex}
            titleAttrs={attr.title}
          />
        </div>
      </div>,
      // Note: see gatsby-ssr.js for the dialog-portal render
      window.document.getElementById("dialog-portal")
    )
  }

  return (
    <li ref={listItemRef}>
      <ButtonNavHeader
        _sx={{
          visibility: instance?.shown ? "hidden" : "visible", // Using visibility here so that the layout doesn't shift even when it's hidden
        }}
        menuItem={menuItem}
        activeTheme={activeTheme}
        onClick={() => instance.show()}
      />
      {dialog}
    </li>
  )
}

NavMainParentItem.propTypes = {
  menuItemIndex: PropTypes.number.isRequired,
  menuItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default NavMainParentItem
